export const aboutContent = `Hello! I develop applications in native JavaScript, as well as using libraries and frameworks.
Familiar with Webpack and Vite for bundling and optimizing front-end assets.  Basic knowledge of WordPress, React, Vue.js, Rest API, TypeScript, NodeJS, Jest.\nI constantly improve my skills and keep track of the latest development trends in
  order to be able to use the best solutions for realizable projects.`;

export const works = [
  {
    id: 1,
    title: 'The artist\'s personal website',
    task: 'The project implements the possibility of selling goods, sending a feedback form. Layout: Vite, EJS, SCSS',
    stack: 'WordPress, Underscores, WooCommerce',
    deploy: 'https://artyasin.com/',
    code: 'https://github.com/adypr/bt',
  },
  {
    id: 2,
    title: 'RS Lang',
    task: 'The project is an application for learning English in a playful way. Implemented two mini-games, authorization and a statistics section to control the progress of the study',
    stack: 'SCSS, Webpack, TypeScript, JS, API',
    deploy: 'https://gilded-toffee-2eda7b.netlify.app/',
    code: 'https://github.com/adypr/rslang-clone',
  },
  {
    id: 3,
    title: 'Sheets for virtual piano',
    task: 'The project is a blog for publishing sheet music for a virtual piano. It features a dynamically changing sidebar menu designed as piano keys, adapting to the number of posts. Security and optimization are enhanced using plugins such as All In One WP Security and Wordfence Security.',
    stack: 'WordPress, WP REST API, PHP, JavaScript',
    deploy: 'https://qvadit.com/',
    code: 'https://github.com/adypr/qvadit-wp',
  },
  {
    id: 4,
    title: 'Portfolio',
    task: 'Multilanguage landing site for a photographer. Responsive layout, switch light and dark theme, photo sorting',
    stack: 'PerfectPixel, PUG, SCSS, BEM, Stylelint',
    deploy: 'https://rolling-scopes-school.github.io/adypr-JSFEPRESCHOOL/portfolio/',
    code: 'https://github.com/adypr/portfolio-clone',
  },
  {
    id: 5,
    title: 'Shelter',
    task: 'Two-page site. Responsive layout, carousel slider, burger menu, pagination',
    stack: 'PerfectPixel, PUG, SCSS, BEM, Stylelint, ESLint with Airbnb, Webpack',
    deploy: 'https://rolling-scopes-school.github.io/adypr-JSFE2022Q1/shelter/pages/main/',
    code: 'https://github.com/adypr/shelter-clone',
  },

];

export const otherWorks = [
  {
    id: 6,
    title: 'Online store',
    task: 'The project in JavaScript. Implemented adding products to the cart, search by product cards, cross-filtering and sorting',
    stack: 'SCSS, ESLint, TypeScript, Webpack, Jest',
    deploy: 'https://rolling-scopes-school.github.io/adypr-JSFE2022Q1/store/',
    code: 'https://github.com/adypr/online-store-clone',
  },
  {
    id: 7,
    title: 'Organic food',
    task: 'React project using uncontrolled forms, no libraries',
    stack: 'React, CRA, TypeScript, Jest',
    deploy: 'https://react2022q3-organic-foods.netlify.app/',
    code: 'https://github.com/adypr/organic-foods',
  },
  {
    id: 8,
    title: 'Virtual Keyboard',
    task: 'The project in JavaScript. All HTML generation is done through JS, without the use any JS libraries and frameworks',
    stack: 'StyleLint, ESLint, Webpack',
    deploy: 'https://adypr.github.io/virtual-keyboard/',
    code: 'https://github.com/adypr/virtual-keyboard',
  },
  {
    id: 9,
    title: 'Async Race',
    task: 'Mini-game in JavaScript. All HTML generation is done through JS, without the use any JS libraries and frameworks. ',
    stack: 'TypeScript, StyleLint, ESLint, Webpack, Json-server',
    deploy: 'https://638bab6a97124e6b9e6374ed--silly-marshmallow-7b0b33.netlify.app/',
    code: 'https://github.com/adypr/async-race-clone',
  },
];

export const skills = ['SCSS', 'BEM', 'JS', 'TypeScript', 'Webpack', 'Vite', 'Jest', 'Git'];

export const contacts = [
  { icon: 'github', link: 'https://github.com/adypr', text: 'Github' },
  { icon: 'codewars', link: 'https://www.codewars.com/users/Adypr', text: 'Codewars' },
  { icon: 'mail', link: 'mailto:aycodeacc@gmail.com="Question about CV"', text: 'aycodeacc@gmail.com' },
  { icon: 'discord', link: 'https://discordapp.com/users/622121889544011791/', text: 'Aida Y#1689' },
  { icon: 'linkedin', link: 'https://linkedin.com/in/aycodeacc', text: 'linkedin.com/in/aycodeacc' },
];

export const educations = [
  {
    id: 5, title: 'RS School', course: 'Frontend JavaScript 2022Q1', year: '2022', link: 'https://app.rs.school/certificate/pk05jq20',
  },
  {
    id: 6, title: 'FreeCodeCamp', course: 'JavaScript Algorithms and Data Structures', year: '2019', link: 'https://www.freecodecamp.org/certification/fcc32748869-940a-4adf-808b-e19cbb7e14af/javascript-algorithms-and-data-structures',
  },
  {
    id: 7, title: 'FreeCodeCamp', course: 'Web Design', year: '2019', link: 'https://www.freecodecamp.org/certification/fcc32748869-940a-4adf-808b-e19cbb7e14af/responsive-web-design',
  },
];
